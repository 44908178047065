import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axiosInit from './utils/axiosInit'
import axiosSetHeaderToken from './utils/axiosSetHeaderToken'

axiosInit()

if (store.getters.isLoggedIn) {
  axiosSetHeaderToken({ token: store.state.user.accessToken })
  store.dispatch('getGlobalLists')
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
