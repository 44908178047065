<template>
  <v-snackbar top rounded="xl" v-model="snackbar.open" :color="snackbar.color">
    {{ snackbar.text }}

    <template #action="{ attrs }">
      <v-btn
        icon
        text
        v-bind="attrs"
        @click="
          toggleSnackbar({
            open: false
          })
        "
      >
        <v-icon small> mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      snackbar: (state) => state.base.snackbar
    })
  },
  methods: {
    ...mapActions(['toggleSnackbar'])
  }
}
</script>

<style lang="scss" scoped></style>
