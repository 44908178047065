import { set } from 'vue'

export default {
  state: {
    snackbar: {
      open: false,
      text: null,
      color: null
    },
    dialog: {
      open: false,
      persistent: true,
      component: null,
      width: null
    },
    vDataTableDefaults: {
      noResultsText: 'Δεν βρέθηκαν αποτελέσματα',
      noDataText: 'Δεν βρέθηκαν αποτελέσματα',
      loadingText: 'Παρακαλώ περιμένετε...',
      headerProps: {
        sortByText: 'Ταξινόμηση ανά'
      },
      footerProps: {
        itemsPerPageText: 'Αντικείμενα ανά σελίδα',
        itemsPerPageAllText: 'Όλα'
      }
    }
  },
  mutations: {
    SET_SNACKBAR(state, { open, text, color = null }) {
      set(state.snackbar, 'open', open)
      set(state.snackbar, 'text', text)
      set(state.snackbar, 'color', color)
    },
    toggleDialog(state, dialog) {
      set(state, 'dialog', {
        ...state.dialog,
        ...dialog
      })
    }
  },
  actions: {
    toggleSnackbar({ state, commit }, snackbar) {
      // if snackbar is open
      if (state.open) {
        // close the snackbar
        commit('SET_SNACKBAR', { open: false, ...snackbar })
      } else {
        // open the snackbar
        commit('SET_SNACKBAR', { open: true, ...snackbar })
      }
    }
  }
}
