import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    data: []
  },
  mutations: {
    SET_NUMBER_POOLS(state, data) {
      set(state, 'data', data)
    }
  },
  actions: {
    async getNumberPools({ commit }) {
      const res = await axios.get('/numberPool/list', {
        params: { page: 1, limit: 50, deleted: false }
      })
      commit('SET_NUMBER_POOLS', res.data.content)
    },
    async addNumberPool({ dispatch }, data) {
      const res = await axios.post('/numberPool', data)
      dispatch('getNumberPools')
      return res.status
    },
    async editNumberPool({ dispatch }, { id, data }) {
      const res = await axios.put(`/numberPool/${id}`, data)
      dispatch('getNumberPools')
      return res.status
    }
  }
}
