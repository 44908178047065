import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    data: [],
    filters: {
      macAddress: null,
      serialNumber: null,
      pseudoDeviceId: null,
      phone: null,
      status: null,
      availability: null,
      manufacturer: null,
      modelName: null
    }
  },
  mutations: {
    SET_DEVICES(state, data) {
      set(state, 'data', data)
    },
    SET_DEVICES_FILTER_VALUE(state, { key, value }) {
      set(state.filters, key, value)
    },
    resetDevicesFilters(state) {
      set(state, 'filters', {
        macAddress: null,
        serialNumber: null,
        pseudoDeviceId: null,
        phone: null,
        status: null,
        availability: null,
        maker: null,
        device: null,
        manufacturer: null,
        modelName: null
      })
    }
  },
  actions: {
    async getDevices({ state, commit }, payload) {
      const params = { ...state.filters }

      if (payload?.isRequest) {
        params.isRequest = 'true'
      }

      const res = await axios('/device/number/list', {
        params
      })
      commit('SET_DEVICES', res.data.content)
    },
    async addDevice({ dispatch }, data) {
      const res = await axios.post('/device', data)
      dispatch('getDevices')
      return res.status
    },
    async editDevice({ dispatch }, { id, data }) {
      const res = await axios.put(`/device/${id}`, data)
      dispatch('getDevices')
      return res.status
    },
    async bulkImportDevices({ dispatch }, data) {
      const res = await axios.post('/inventory/bulk/import', data)
      dispatch('getDevices')
      return res.status
    },
    async exportDevices({ state }) {
      const params = { ...state.filters }
      const res = await axios.get('/inventory/bulk/export', {
        maxBodyLength: Infinity,
        responseType: 'blob',
        params
      })
      const filename = res.headers['content-disposition'].split('filename=')[1]
      const link = document.createElement('a')
      const url = window.URL.createObjectURL(new Blob([res.data]))
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },

    async syncDevices({ state }) {
      const params = { ...state.data }
      const res = await axios.post('cucm/devices/sync', {
        params
      })
      return res.status
    },

    setDevicesFilterValue({ commit, dispatch }, { key, value }) {
      commit('SET_DEVICES_FILTER_VALUE', { key, value })

      dispatch('getDevices')
    },
    resetDevicesFilters({ commit, dispatch }) {
      commit('resetDevicesFilters')

      dispatch('getDevices')
    }
  }
}
