import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import base from './base'
import globalLists from './globalLists'
import devices from './devices'
import numberPools from './numberPools'
import requests from './requests'
import settingsTemplates from './settingsTemplates'
import lineGroups from './lineGroups'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    base,
    globalLists,
    devices,
    numberPools,
    requests,
    settingsTemplates,
    lineGroups
  }
})
