import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    warehouses: [],
    models: [],
    deviceStatusList: []
  },
  mutations: {
    SET_WAREHOUSES(state, payload) {
      set(state, 'warehouses', payload)
    },
    SET_MODELS(state, payload) {
      set(state, 'models', payload)
    },
    SET_STATUS_LIST(state, payload) {
      set(state, 'deviceStatusList', payload)
    }
  },
  actions: {
    getGlobalLists({ dispatch }) {
      dispatch('getWarehousesList')
      dispatch('getModelsList')
      dispatch('getDeviceStatusList')
    },
    async getWarehousesList({ commit }) {
      const res = await axios('/warehouse/list')
      commit('SET_WAREHOUSES', res.data.content)
    },
    async getModelsList({ commit }) {
      const res = await axios('/device/model/list')
      commit('SET_MODELS', res.data.content)
    },
    async getDeviceStatusList({ commit }) {
      const res = await axios('/deviceStatus/list')
      commit('SET_STATUS_LIST', res.data.content)
    }
  }
}
