import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    data: []
  },
  mutations: {
    SET_LINE_GROUPS(state, data) {
      set(state, 'data', data)
    }
  },
  actions: {
    async getLineGroups({ commit }) {
      const res = await axios.get('/cucm/callPickupGroup/list')
      commit(
        'SET_LINE_GROUPS',
        res.data.map((item) => {
          return {
            name: item
          }
        })
      )
    }
    // async addLineGroup({ dispatch }, data) {
    //   const res = await axios.post('/lineGroup', data)
    //   dispatch('getLineGroup')
    //   return res.status
    // },
    // async editLineGroup({ dispatch }, { id, data }) {
    //   const res = await axios.put(`/lineGroup/${id}`, data)
    //   dispatch('getLineGroup')
    //   return res.status
    // }
  }
}
