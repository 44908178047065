import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/requests'
  },
  {
    path: '/login',
    component: () => import('./views/LoginView.vue'),
    meta: { guestRoute: true }
  },
  {
    path: '/requests',
    component: () => import('./views/Requests/RequestsIndex.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/requests/add',
    component: () => import('./views/Requests/AddRequest/AddRequestIndex.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/uninstalls',
    component: () => import('./views/UninstallRequests.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/devices',
    component: () => import('./views/Devices/DevicesIndex.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/devices/edit/:id',
    component: () => import('./views/Devices/EditDevice.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/number-pools',
    component: () => import('./views/NumberPools/NumberPoolsIndex.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/number-pool/:id',
    component: () => import('./views/NumberPools/NumberPoolDetailsView.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/settings-templates',
    component: () =>
      import('./views/SettingsTemplates/SettingsTemplatesIndex.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/settings-templates/add',
    component: () =>
      import('./views/SettingsTemplates/AddSettingsTemplate.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/settings-templates/edit/:id',
    component: () =>
      import('./views/SettingsTemplates/EditSettingsTemplate.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/line-groups',
    component: () => import('./views/LineGroups/LineGroupsIndex.vue'),
    meta: { authRoute: true }
  },
  {
    path: '/line-groups/edit/:name',
    component: () => import('./views/LineGroups/EditLineGroup.vue'),
    meta: { authRoute: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { isLoggedIn } = store.getters

  const isAuthRoute = to.meta.authRoute
  if (isAuthRoute && !isLoggedIn) {
    // auth.returnUrl = to.fullPath;
    next('/login')
    return
  }

  const isGuestRoute = to.meta.guestRoute
  if (isGuestRoute && isLoggedIn) {
    // auth.returnUrl = to.fullPath;
    next('/installs')
    return
  }

  next()
})

export default router
