import axios from 'axios'
import { set } from 'vue'
import axiosSetHeaderToken from '../utils/axiosSetHeaderToken'
import axiosDeleteHeaderToken from '../utils/axiosDeleteHeaderToken'

const TOKEN_KEY = 'access_token'
const USER_DATA_KEY = 'user_data_token'

export default {
  state: {
    accessToken: localStorage.getItem(TOKEN_KEY) || null,
    data: JSON.parse(localStorage.getItem(USER_DATA_KEY)) || null
  },
  getters: {
    isLoggedIn: (state) => !!state.accessToken
  },
  mutations: {
    SET_USER_DATA(state, payload) {
      set(state, 'data', payload)
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(payload))
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      set(state, 'accessToken', accessToken)
      localStorage.setItem(TOKEN_KEY, accessToken)
    },
    DELETE_TOKEN(state) {
      set(state, 'accessToken', null)
      localStorage.removeItem(TOKEN_KEY)
    }
  },
  actions: {
    async credentialsLogin({ commit, dispatch }, { username, password }) {
      try {
        const res = await axios.post('/authentication/login', {
          username,
          password
        })
        commit('SET_USER_DATA', res.data.userDetails)
        commit('SET_ACCESS_TOKEN', res.data.token)

        axiosSetHeaderToken({ token: res.data.token })

        dispatch('getGlobalLists')
      } catch (error) {
        throw new Error()
      }
    },
    logout({ commit }) {
      commit('DELETE_TOKEN')
      axiosDeleteHeaderToken()
    }
  }
}
