import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    data: [],
    settingsNames: {}
  },
  mutations: {
    SET_SETTINGS_TEMPLATES(state, data) {
      set(state, 'data', data)
    },
    SET_SETTINGS_NAMES(state, payload) {
      set(state, 'settingsNames', payload)
    }
  },
  getters: {
    getSettingsNamesByType: (state) => (type) => state.settingsNames[type]
  },
  actions: {
    async getSettingsNamesList({ commit }) {
      const { data: lineSettings } = await axios('/setting/name/list', {
        params: {
          type: 'LINE'
        }
      })
      const { data: deviceSettings } = await axios('/setting/name/list', {
        params: {
          type: 'DEVICE'
        }
      })
      const { data: huntPilotSettings } = await axios('/setting/name/list', {
        params: {
          type: 'HUNT_PILOT'
        }
      })
      commit('SET_SETTINGS_NAMES', {
        LINE: lineSettings.content,
        DEVICE: deviceSettings.content,
        HUNT_PILOT: huntPilotSettings.content
      })
    },
    async getSettingsTemplates({ commit }) {
      const res = await axios('/settings/template/list')
      commit('SET_SETTINGS_TEMPLATES', res.data.content)
    },
    async addSettingsTemplate({ dispatch }, data) {
      const res = await axios.post('/settings/template', data)
      dispatch('getSettingsTemplates')
      return res.status
    },
    async editSettingsTemplate({ dispatch }, { id, data }) {
      const res = await axios.put(`/settings/template/${id}`, data)
      dispatch('getSettingsTemplates')
      return res.status
    },
    async removeSettingsTemplate({ dispatch }, { id }) {
      const res = await axios.delete(`/settings/template/${id}`)
      dispatch('getSettingsTemplates')
      return res.status
    }
  }
}
