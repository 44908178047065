<template>
  <v-app-bar color="white" class="pr-9" app dense elevation="1">
    <span class="font-weight-medium text-body-1 ml-auto mr-2">
      <v-icon left> mdi-account </v-icon>
      {{ userName }}
    </span>

    <v-divider vertical class="mx-4" />

    <v-btn text rounded class="text-capitalize" @click="onLogout">
      <v-icon left> mdi-logout</v-icon>
      Έξοδος
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      userData: (state) => state.user.data
    }),
    userName() {
      return `${this.userData.firstName} ${this.userData.lastName}`
    }
  },
  methods: {
    ...mapActions(['logout']),
    onLogout() {
      this.logout()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped></style>
