import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    data: []
  },
  mutations: {
    SET_REQUESTS(state, data) {
      set(state, 'data', data)
    }
  },
  actions: {
    async getRequests({ commit }) {
      const res = await axios('/request/list')
      commit('SET_REQUESTS', res.data.content)
    },
    async addRequest({ dispatch }, data) {
      const res = await axios.post('/request', data)
      dispatch('getRequests')
      return res.status
    },
    async editRequest({ dispatch }, { id, data }) {
      const res = await axios.put(`/request/${id}`, data)
      await dispatch('getRequests')
      return res.status
    }
  }
}
