import axios from 'axios'
import store from '../store'
import router from '../router'

export default function init() {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (err.response.status === 401) {
        store.dispatch('logout')
        router.push('/login')
      }
      throw err
    }
  )
}
